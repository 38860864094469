<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-progress-linear indeterminate v-if="this.loading" color="primary"/>
      </v-col>
      <v-col cols="12">
      <v-alert v-if="this.alreadyExists" color="info" type="info" outlined>
        {{ $t('settings.lang_fiscallyTseAlreadyBeenAdded') }}
      </v-alert>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined :label="$t('settings.lang_fiscalDeviceName')" v-model="form.name"/>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field outlined  :label="$t('settings.lang_fiscalDeviceDescr')" v-model="form.description"/>
      </v-col>


      <v-col cols="12" sm="6">
        <v-text-field outlined :label="$t('settings.lang_apiKey')" v-model="form.api_key"/>
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field outlined type="password" :label="$t('settings.lang_secretKey')" v-model="form.api_secret_key"/>
      </v-col>

      <v-col class="text-right" cols="12">
        <v-spacer/>
        <v-btn :loading="this.saving_loader" @click="save" :disabled="this.saving_loader" depressed class="mx-auto" color="success">{{this.$t('generic.lang_save')}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";

export default {
  name: "FiskalyComponent",
  data() {
    return {
      saving_loader: false,
      alreadyExists: false,
      loading: false,
      form: {
        name: "",
        description: ""
      }
    }
  },
  methods: {
    loadData() {
      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICEFISKALY.GET).then((res) => {
        if (res.data.status === 'SUCCESS') {
          if(res.data.data != "empty"){
            this.form = {...res.data.data};
            this.alreadyExists = true;
            this.saving_loader = true;
          }else{
            this.alreadyExists = false;
            this.saving_loader = false;
          }


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    save() {
      this.saving_loader = true;
      this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICEFISKALY.CREATE, this.form).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('settings.lang_fiscalDeviceAdded'),
            color: "success"
          });
        }else if(res.data.status === false){
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.saving_loader = false;
      });
    }
  },
  mounted() {
    this.loadData();
    this.saving_loader = 1;
  }
}
</script>

<style scoped>

</style>
