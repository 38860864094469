<template>
    <v-container fluid>
        <div v-if="!loading">
            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalDeviceName"
                                  :label="$t('settings.lang_fiscalDeviceName')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalDeviceDescr"
                                  :label="$t('settings.lang_fiscalDeviceDescr')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalDeviceIP"
                                  :rules="[rules.ipv4]"
                                  placeholder="192.168.178.x"
                                  :label="$t('settings.lang_fiscalDeviceIP')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>
                <v-flex md6 mr-2 ml-2>
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="fiscalDeviceID"
                                  :label="$t('settings.lang_fiscalDeviceID')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-flex>
            </v-layout>

            <v-row>
                <v-col cols="12" md="6">
                    <v-switch :label="$t('settings.lang_proxyServer')" :true-value="1" :false-value="0" v-model="useProxy"></v-switch>
                </v-col>

                <v-col v-if="useProxy === 1" cols="6" md="6">
                    <v-text-field outlined
                                  @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  v-model="proxyServerIP"
                                  :rules="[rules.ipv4]"
                                  placeholder="192.168.178.x"
                                  :label="$t('settings.lang_fiscalProxyIP')"
                                  autocomplete="off"
                                  required
                    ></v-text-field>
                </v-col>

              <v-col cols="6">
                <v-switch v-if="useProxy === 0" :value="1" :label="$t('settings.lang_sslActive')" v-model="fiscalDeviceSSL"/>

              </v-col>
            </v-row>
          <v-divider></v-divider>
            <v-layout>

              <v-col cols="6">
                <v-btn color="error" @click="showDeactivateDialog = true">{{ $t('settings.lang_disableFiscalDevice') }}</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="warning" @click="showCloseTransactionDialog = true">{{ $t('settings.lang_closeOpenTransactions') }}</v-btn>
              </v-col>
            </v-layout>

            <v-layout>
                <v-flex md12 class="text-right">
                    <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                    <v-btn color="success" :disabled="fiscalDeviceName.length < 1" @click="saveData">{{
                            $t('generic.lang_save')
                        }}
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <!-- Loading Spinner -->
        <div v-else>
            <v-layout>
                <v-flex cols="12" class="text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-flex>
            </v-layout>
        </div>

        <!-- SETUP DIALOG -->
        <FiscalDeviceSetup ref="fiscalDeviceSetup" :skipCallbackEvents="showDeactivateDialog" :fiscal-device-i-d="fiscalDeviceID" :fiscal-device-i-p="fiscalDeviceIP" :fiscal-device-name="fiscalDeviceName" :use-proxy="(useProxy === 1)" :proxy-server-i-p="proxyServerIP" @cancelSetup="cancelSetup" @setupFinish="setupFinished"></FiscalDeviceSetup>
        <FiscalDeviceDeactivate :fiscal-device-name="fiscalDeviceName" :fiscal-device-i-p="fiscalDeviceIP" :fiscal-device-i-d="fiscalDeviceID" :showDialog="showDeactivateDialog" @closeDialog="showDeactivateDialog = false"></FiscalDeviceDeactivate>

      <FiscalDeviceCloseTransactions :fiscal-device-name="fiscalDeviceName" :fiscal-device-i-p="fiscalDeviceIP" :fiscal-device-i-d="fiscalDeviceID" :showDialog="showCloseTransactionDialog" @closeDialog="showCloseTransactionDialog = false"></FiscalDeviceCloseTransactions>


        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import FiscalDeviceSetup from "@/components/common/fiscalDevice/FiscalDeviceSetup";
import {mapGetters} from "vuex";
import FiscalDeviceDeactivate from "@/components/common/fiscalDevice/FiscalDeviceDeactivate";
import FiscalDeviceCloseTransactions from "@/components/common/fiscalDevice/FiscalCloseTransactions";

export default {
    name: "FiscalDevicesEdit",
    components: {FiscalDeviceDeactivate, FiscalDeviceSetup,FiscalDeviceCloseTransactions},
    mixins: [mixin],

    props: {
        fiscalID: Number
    },

    data() {
        return {
            ENDPOINTS,
            fiscalDeviceName: "",
            fiscalDeviceDescr: "",
            fiscalDeviceIP: "",
            fiscalDeviceID: "local_TSE",
            fiscalDeviceSSL: 0,
            useProxy: 0,
            proxyServerIP: "",
            loading: false,
            rules: {
                ipv4: value => {
                    const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                    return pattern.test(value) || 'Ungültige IPv4 Adresse'
                },
            },
            showDeactivateDialog: false,
            showCloseTransactionDialog: false,
        }
    },

    mounted() {
        this.getFiscalDevice();
    },

    methods: {
        getFiscalDevice() {
            this.loading = true;

            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.GET, {
                fiscalDeviceID: this.fiscalID
            }).then((res) => {
                if (res.status === 200) {
                    this.fiscalDeviceName = res.data.formfillData.textFields.fiscalDeviceName;
                    this.fiscalDeviceDescr = res.data.formfillData.textFields.fiscalDeviceDescr;
                    this.fiscalDeviceIP = res.data.formfillData.textFields.fiscalDeviceIP;
                    this.fiscalDeviceID = res.data.formfillData.textFields.fiscalDeviceID;
                    this.fiscalDeviceSSL = res.data.formfillData.textFields.fiscalDeviceSSL;
                    this.useProxy = res.data.formfillData.textFields.useTSEProxy;
                    this.proxyServerIP = res.data.formfillData.textFields.TSEProxyIPAdress;

                    this.loading = false;
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        cancelSetup() {
            this.tseLoading = false;
            this.tseErrorMessage = "";
        },
        resetData() {
            this.fiscalDeviceID = "local_TSE";
            this.fiscalDeviceName = "";
            this.fiscalDeviceDescr = "";
            this.fiscalDeviceIP = "";
            this.fiscalDeviceSSL = 0;
            this.useProxy = 0;
            this.proxyServerIP = "";
        },
        saveData() {
            //START SETUP DEVICE
            this.$refs.fiscalDeviceSetup.checkDevice();
        },
        setupFinished(tseStorageInfo) {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.UPDATE, {
                fiscalID: this.fiscalID,
                fiscalDeviceName: this.fiscalDeviceName,
                fiscalDeviceDescr: this.fiscalDeviceDescr,
                fiscalDeviceIP: this.fiscalDeviceIP,
                fiscalDeviceID: this.fiscalDeviceID,
                fiscalDeviceSSL: this.fiscalDeviceSSL,
                tseStorageInfo: tseStorageInfo,
                TSEProxyIPAdress: this.proxyServerIP,
                useTSEProxy: this.useProxy
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_actionSuccessful'),
                        color: "success"
                    });

                    this.showList();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        showList() {
            this.resetData();

            this.$emit("showList");
        }
    }

}
</script>