<template>
    <v-container fluid>
        <v-layout>
            <v-flex md6 mr-2 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="fiscalDeviceName"
                              :label="$t('settings.lang_fiscalDeviceName')"
                              autocomplete="off"
                              required
                ></v-text-field>
            </v-flex>
            <v-flex md6 mr-2 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="fiscalDeviceDescr"
                              :label="$t('settings.lang_fiscalDeviceDescr')"
                              autocomplete="off"
                              required
                ></v-text-field>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex md6 mr-2 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="fiscalDeviceIP"
                              :rules="[rules.ipv4]"
                              placeholder="192.168.178.x"
                              :label="$t('settings.lang_fiscalDeviceIP')"
                              autocomplete="off"
                              required
                ></v-text-field>
            </v-flex>
            <v-flex md6 mr-2 ml-2>
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="fiscalDeviceID"
                              :label="$t('settings.lang_fiscalDeviceID')"
                              autocomplete="off"
                              required
                ></v-text-field>

                <v-btn color="primary" small v-if="isTSEServer" @click="$refs.tseServerDialog.checkServer()">{{ $t('settings.lang_discoverDevices') }}</v-btn>
            </v-flex>
        </v-layout>

        <v-row>
            <v-col cols="12" md="6">
                <v-switch :label="$t('settings.lang_useProxyServer')" :true-value="1" :false-value="0" v-model="useProxyServer"></v-switch>
            </v-col>

            <v-col v-if="useProxyServer === 1" cols="12" md="6">
                <v-col cols="12" md="6">
                    <v-switch :label="$t('settings.lang_fiscalDeviceViaTseServer')" v-model="isTSEServer"></v-switch>
                </v-col>

                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="TSEProxyIPAddress"
                              :rules="[rules.ipv4]"
                              placeholder="192.168.178.x"
                              :label="$t('settings.lang_fiscalProxyIP')"
                              autocomplete="off"
                              required
                ></v-text-field>
            </v-col>
        </v-row>

        <v-row v-if="useProxyServer === 0">
            <v-col cols="12" md="6">
                <v-switch :value="1" :label="$t('settings.lang_sslActive')" v-model="fiscalDeviceSSL"/>
            </v-col>
        </v-row>

        <v-layout>
            <v-flex md12 class="text-right">
                <v-btn text color="error" @click="showList">{{ $t('generic.lang_prev') }}</v-btn>
                <v-btn color="success" :disabled="fiscalDeviceName.length < 1" @click="addData">{{ $t('generic.lang_add') }}</v-btn>
            </v-flex>
        </v-layout>

        <!-- SETUP DIALOG -->
        <FiscalDeviceSetup ref="fiscalDeviceSetup" :use-proxy="(useProxyServer === 1)" :use-s-s-l="(fiscalDeviceSSL === 1)" :proxy-server-i-p="TSEProxyIPAddress" :fiscal-device-i-d="fiscalDeviceID" :fiscal-device-i-p="fiscalDeviceIP" :fiscal-device-name="fiscalDeviceName" @setupFinish="setupFinished"></FiscalDeviceSetup>
        <FiscalDevicesCreateTSEServerDialog ref="tseServerDialog" :server-i-p="TSEProxyIPAddress" :device-i-p="fiscalDeviceIP" @selectSlot="slotSelected"></FiscalDevicesCreateTSEServerDialog>

        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import FiscalDeviceSetup from "@/components/common/fiscalDevice/FiscalDeviceSetup";
import FiscalDevicesCreateTSEServerDialog from "@/components/settings/fiscal_de/FiscalDevicesCreateTSEServerDialog";

export default {
    name: "FiscalDevicesCreate",

    components: {
        FiscalDevicesCreateTSEServerDialog,
        FiscalDeviceSetup
    },

    mixins: [mixin],

    data() {
        return {
            ENDPOINTS,
            fiscalDeviceName: "",
            fiscalDeviceDescr: "",
            fiscalDeviceIP: "",
            fiscalDeviceID: "local_TSE",
            fiscalDeviceSSL: 0,
            useProxyServer: 0,
            isTSEServer: false,
            TSEProxyIPAddress: "",
            loading: false,
            rules: {
                ipv4: value => {
                    const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
                    return pattern.test(value) || 'Ungültige IPv4 Adresse'
                },
            }
        }
    },

    watch: {
        useProxyServer(val) {
            if(val === 0) {
                this.isTSEServer = false;
                this.TSEProxyIPAddress = "";
            }
        }
    },

    methods: {
        resetData() {
            this.fiscalDeviceID = "local_TSE";
            this.fiscalDeviceName = "";
            this.fiscalDeviceDescr = "";
            this.fiscalDeviceIP = "";
            this.fiscalDeviceSSL = 0;
            this.useProxyServer = 0;
            this.TSEProxyIPAddress = "";
        },
        addData() {
            this.$refs.fiscalDeviceSetup.checkDevice();
        },
        setupFinished(tseStorageInfo) {
            this.axios.post(ENDPOINTS.SETTINGS.FISCAL.FISCALDEVICE.CREATE, {
                fiscalDeviceName: this.fiscalDeviceName,
                fiscalDeviceDescr: this.fiscalDeviceDescr,
                fiscalDeviceIP: this.fiscalDeviceIP,
                fiscalDeviceID: this.fiscalDeviceID,
                fiscalDeviceSSL: this.fiscalDeviceSSL,
                tseStorageInfo: tseStorageInfo,
                useProxyServer: this.useProxyServer,
                proxyIPAddress: this.TSEProxyIPAddress
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                    Events.$emit("showSnackbar", {
                        message: this.$t('settings.lang_fiscalDeviceAdded'),
                        color: "success"
                    });

                    this.showList();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch(() => {
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            });
        },
        showList() {
            this.resetData();

            this.$emit("showList");
        },
        slotSelected(module) {
            this.fiscalDeviceID = "TSE_"+module.tseInformation.serialNumber;
        }
    }

}
</script>